







































import {Component, Vue} from 'vue-property-decorator';
import {
  namespace,
} from 'vuex-class';
import Tenant from '@/models/Tenant';
import SideCardComponent from '@/components/shared/SideCard.component.vue';
import TenantTableComponent from '@/components/tenant/TenantTable.component.vue';
import {tenantStoreActions, tenantStoreGetter} from '@/stores/tenant.store';
import repo from '@/api/repositories/TenantRepository.ts';
import {getAllWeekDays} from '@/misc/Enums/WeekDay';

const TenantStore = namespace('tenant');

@Component({
  components: {
    TenantTableComponent,
    SideCardComponent,
    TenantManageComponent: () => import(
        /* webpackChunkName: "tenantManageComponent" */
        '@/components/tenant/TenantManage.component.vue'),
    ExportAccountingModal: () => import (
        '@/components/shared/ExportAccounting.component.vue'),
    SearchBarComponent: () => import(
        '@/components/shared/SearchBar.component.vue'),
    ToggleButtonGroupComponent: () => import(
        '@/components/shared/ToggleButtonGroup.component.vue'),
  },
})
export default class TenantsOverviewView extends Vue {

  @TenantStore.Action(tenantStoreActions.LOAD_TENANTS_ACTION)
  private loadTenantsAction!: () => void;
  @TenantStore.Getter(tenantStoreGetter.TENANTS)
  private _tenants!: Tenant[];

  get tenants(): Tenant[] {
    return this._tenants;
  }

  private showAccountingExport: boolean = false;

  private filterOptions: any[] = [];
  private selectedFilter: string = 'all';
  private searchString: string = '';

  private tenantStyle: number = 0;
  private showCreateTenantModal: boolean = false;

  public get filteredTenants(): Tenant[] {
    let filteredTenants = this.tenants.filter((tenant) => {
      // compares names with the input of the searchbar
      return tenant.name && tenant.name
          .toLowerCase().includes(this.searchString.toLowerCase()) ||
      // compares street with the input of the searchbar
      tenant.billingAddress?.street && tenant.billingAddress?.street
          .toLowerCase().includes(this.searchString.toLowerCase()) ||
      // compares email with the input of the searchbar
      tenant.email && tenant.email
          .toLowerCase().includes(this.searchString.toLowerCase());
    });
    if (this.selectedFilter !== 'all') {
      if (this.selectedFilter === 'test') {
        filteredTenants = filteredTenants.filter((tenant) => tenant.isTest);
      } else {
        filteredTenants = filteredTenants.filter((tenant) => !tenant.isTest);
      }
    }
    return filteredTenants;
  }

  public async beforeMount() {
    this.filterOptions = [
      {
        text: this.$t('GENERAL.ALL').toString(), value: 'all',
      },
      {
        text: this.$t('TENANTS_OVERVIEW.TABLE.NO_TEST').toString(), value: 'noTest',
      },
      {
        text: this.$t('TENANTS_OVERVIEW.TABLE.IS_TEST').toString(), value: 'test',
      },

    ];
    try {
      await this.loadTenantsAction();
    } catch (e) {
      this.$notifyErrorSimplified('GENERAL.NOTIFICATIONS.GENERAL_ERROR');
    }
  }
}
