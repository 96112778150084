




















































































































import {Component, Prop, VModel, Vue} from 'vue-property-decorator';
import Tenant from '@/models/Tenant';
import {CountryFlags} from '@/misc/CountryStorage';
import {namespace} from 'vuex-class';
import {tenantStoreActions, tenantStoreMutations} from '@/stores/tenant.store';
import {Permission} from '@/misc/Enums/permission.enum';
import ActionMenuComponent from '@/components/shared/table/ActionMenu.component.vue';
import TenantManageComponent from '@/components/tenant/TenantManage.component.vue';
import SideCardComponent from '@/components/shared/SideCard.component.vue';

const tenantStore = namespace('tenant');

/**
 * Simple component that lists all companies in a table
 */
@Component({
  components: {
    SideCardComponent,
    TenantManageComponent,
    ActionMenuComponent,
    TableComponent: () => import(
        '@/components/shared/table/Table.component.vue'),
  },
})
export default class TenantTableComponent extends Vue {

  @VModel({default: '', type: String})
  public searchString!: string;
  @Prop({default: []})
  private tenants!: Tenant[];

  @tenantStore.Mutation(tenantStoreMutations.ACTIVE_TENANT)
  private activeTenant!: (tenant: Tenant) => void;

  @tenantStore.Action(tenantStoreActions.EDIT_TENANT_ACTION)
  private editTenant!: (tenant: Tenant) => Promise<Tenant>;

  @tenantStore.Action(tenantStoreActions.DELETE_TENANT_ACTION)
  private deleteTenant!: (tenant: Tenant) => Promise<Tenant>;


  private countryFlags: any = {};
  private showChangeStatusDialog: boolean = false;
  private selectedTenant: Tenant = new Tenant();

  private showDeleteTenantDialog: boolean = false;
  private showEditTenantModal: boolean = false;

  constructor() {
    super();
  }

  private mounted() {
    this.countryFlags = CountryFlags;
  }

  /**
   * User delete functions
   */
  public onTenantDeleteClick(tenant: Tenant) {
    this.showDeleteTenantDialog = true;
    this.selectedTenant = tenant;
  }

  public async onTenantEditClick(tenant: Tenant) {
    this.showEditTenantModal = true;
    this.selectedTenant = tenant;
  }

  public async onTenantDelete(tenant: Tenant) {
    this.showDeleteTenantDialog = false;
    try {
      await this.deleteTenant(tenant);
      this.$notifySuccessSimplified('TENANT_MANAGE.NOTIFICATIONS.DELETE.SUCCESS');
    } catch (e) {
      this.$notifyErrorSimplified('TENANT_MANAGE.NOTIFICATIONS.DELETE.ERROR');
    }
  }

  public async onExitModal() {
    // Update tenant on Modal Close
    this.showEditTenantModal = false;
  }

  private isEditable(): boolean {
    return this.$userRoleHandler.hasPermission(Permission.TENANT_UPDATE_OWN);
  }

  private get importantKeys() {
    return {name: String, billingAddress: {street: String, city: String}};
  }

  private get tableHeaders(): any {
    return [
      {
        text: this.$t('TENANTS_OVERVIEW.TABLE.TENANT'),
        value: 'name',
        width: '20%',
        class: 'd-flex justify-start align-center',
      },
      {
        text: this.$t('TENANTS_OVERVIEW.TABLE.EMAIL'),
        value: 'email',
        width: '20%',
        class: 'd-flex justify-start align-center',
      },
      {
        text: this.$t('TENANTS_OVERVIEW.TABLE.ADDRESS'),
        value: 'billingAddress',
        width: '15%',
        class: 'd-flex justify-start align-center',
      },
      {text: this.$t('TENANTS_OVERVIEW.TABLE.ZIP'), value: 'postalCode', width: '10%'},
      {text: this.$t('TENANTS_OVERVIEW.TABLE.CITY'), value: 'city', width: '10%'},
      // todo add when new licence model
      // {text: this.$t('TENANTS_OVERVIEW.TABLE.LICENSE_MODEL'), value: 'licenceModel'},
      {text: this.$t('TENANTS_OVERVIEW.TABLE.LICENSES'), value: 'usedLicenses', sortable: false, width: '10%'},
      {text: this.$t('GENERAL.STATUS'), value: 'status'},
      {text: '', value: 'actions', width: '5%', class: 'justify-start'},
    ];
  }

  private async onTenantChangeStatus(updatedTenant: Tenant) {
    this.showChangeStatusDialog = false;

    try {
      updatedTenant.active = !updatedTenant.active;
      updatedTenant = (await this.editTenant(updatedTenant));

      if (updatedTenant.active) {
        this.$notifySuccessSimplified('TENANT_MANAGE.NOTIFICATIONS.TENANT_ACTIVATED');
      } else {
        this.$notifySuccessSimplified('TENANT_MANAGE.NOTIFICATIONS.TENANT_DEACTIVATED');
      }
    } catch (e) {
      this.$notifyErrorSimplified('GENERAL.NOTIFICATIONS.GENERAL_ERROR');
    }
  }

  private onRowClicked(item: Tenant) {
    this.activeTenant(item);
    this.$router.push({name: 'usersOverview', params: {tenantId: item.id}});
  }

  private getTenantColor(tenant: Tenant, isText: boolean) {
    return tenant.getColorForUserState(isText);
  }

  public onTenantStatusToggleClick(tenant: Tenant) {
    if (this.$userRoleHandler.hasPermission(Permission.TENANT_UPDATE_OWN)) {
      this.showChangeStatusDialog = true;
      this.selectedTenant = tenant;
    }
  }

  private getColor(color: string): string {
    return this.$colorHandler.getThemeColor(color);
  }

  private isActive() {
    const inactive: string = (this.$t('GENERAL.INACTIVE') as string).toLowerCase();
    const active: string = (this.$t('GENERAL.ACTIVE') as string).toLowerCase();

    return {
      status: this.selectedTenant.active ? inactive : active,
    };
  }

}
