













































































































































































































import {Component, Prop, Watch} from 'vue-property-decorator';
import {validationMixin} from 'vuelidate';
import {namespace} from 'vuex-class';
import {email, maxLength, minLength, numeric, required, requiredIf} from 'vuelidate/lib/validators';
import Tenant from '@/models/Tenant';
import ErrorMessageHandlerMixin from '@/helper/ErrorMessageHandler.mixin';
import {mixins} from 'vue-class-component';
import User from '@/models/User';
import UserRole from '@/models/user-attributes/UserRole';
import {CountryStorage} from '@/misc/CountryStorage';
import RJTextField from '@/components/shared/custom-vuetify/RJTextField.vue';
import RJSelect from '@/components/shared/custom-vuetify/RJSelect.vue';
import {tenantStoreActions, tenantStoreMutations} from '@/stores/tenant.store';
import {getLicenseModels, LicenseModels} from '@/misc/Enums/Constants.ts';
import {userStoreActions} from '@/stores/user.store';
import Address from '@/models/Address';

const tenantStore = namespace('tenant');
const UserStore = namespace('user');
const AuthStore = namespace('auth');

@Component({
  components: {RJSelect, RJTextField},
  mixins: [validationMixin],
  validations: {
    tenantCopy: {
      name: {required},
      email: {required, email},
      billingAddress: {
        street: {required},
        houseNo: {required, maxLength: maxLength(15)},
        postalCode: {required, numeric, maxLength: maxLength(5)},
        city: {required},
      },
      licenseModel: {required},
    },
    manager: {
      email:
          {
            required: requiredIf(function(this: any) {
              return !this.editMode;
            }),
            email,
          },
      firstName: {
        required: requiredIf(function(this: any) {
          return !this.editMode;
        }),
      },
      lastName: {
        required: requiredIf(function(this: any) {
          return !this.editMode;
        }),
      },
      userName: {
        required: requiredIf(function(this: any) {
          return !this.editMode;
        }),
      },
      phone: {
        required: requiredIf(function(this: any) {
          return !this.editMode;
        }), minLength: minLength(3),
      },
      address: {
        street: { required: requiredIf(function(this: any) {
            return !this.editMode;
          })},
        houseNo: { required: requiredIf(function(this: any) {
            return !this.editMode;
          }), maxLength: maxLength(15)},
        postalCode: { required: requiredIf(function(this: any) {
            return !this.editMode;
          }), numeric, maxLength: maxLength(5)},
        city: { required: requiredIf(function(this: any) {
            return !this.editMode;
          })},
      },
    },
  },
})
export default class TenantManageComponent extends mixins(ErrorMessageHandlerMixin) {

  @Prop({default: () => new Tenant()})
  public tenant!: Tenant;

  @Prop({default: () => false})
  public editMode!: boolean;

  public tenantCopy: Tenant = new Tenant();

  public manager: User = new User();

  public hasSameEmail: boolean = false;
  public submitted: boolean = false;
  public stateButtonAvailable: boolean = false;
  public countries: Array<{ text: string, value: string }> = [];
  public licenseModels: LicenseModels[] = getLicenseModels();
  private hasSamePrefix: boolean = false;
  private hasSameName: boolean = false;
  @tenantStore.Action(tenantStoreActions.CREATE_TENANT_ACTION)
  private createTenant!: (tenant: Tenant) => any;
  @UserStore.Action(userStoreActions.CREATE_USER_ACTION)
  private createUserAction!: (user: User) => Promise<User>;
  @tenantStore.Action(tenantStoreActions.EDIT_TENANT_ACTION)
  private editTenant!: (tenant: Tenant) => any;
  @tenantStore.Mutation(tenantStoreMutations.STORE_TENANT)
  private storeTenant!: (tenant: Tenant) => any;
  @UserStore.Action(userStoreActions.LOAD_USER_BY_EMAIL_ACTION)
  private loadUserByEmail!: (email: string) => Promise<User[]>;
  @UserStore.Action(userStoreActions.LOAD_USER_ROLES_ACTION)
  private loadUserRoles!: (payload: {tenantId: string}) => Promise<UserRole[]>;
  @UserStore.Action(userStoreActions.EDIT_USER_ACTION)
  private editUser!: (user: User) => any;

  public created() {
    this.countries = CountryStorage;
    this.stateButtonAvailable = this.$userRoleHandler.isSuperAdmin()
        && this.tenant.id != null;
    if (this.tenant.id) {
      this.tenantCopy = Tenant.parseFromObject(this.tenant);
      delete this.tenantCopy.users;
      delete this.tenantCopy.locations;
    } else {
      this.tenantCopy.licenseModel = LicenseModels.LOCATIONS;
    }
    // TODO change when multiple Licenses are implemented
  }

  /**
   * Event handler that saves the edition or the creation of tenant object
   */
  public async onSubmit() {
    this.hasSameName = false;
    this.submitted = true;
    // trigger validation
    this.$v.$touch();

    if (this.$v.$invalid) {
      // if invalid scroll to first error input
      // OPTIMIZE the use of a css selector of a vuetify property is not always safe
      requestAnimationFrame(() => this.$vuetify.goTo('.error--text', {offset: 50}));
    } else {
      try {
        if (this.editMode) {
          delete this.tenantCopy.users;
          delete this.tenantCopy.locations;
          await this.editTenant(this.tenantCopy!);
          this.$notifySuccessSimplified('TENANT_MANAGE.NOTIFICATIONS.TENANT_EDITED');
          this.$emit('exitModal');
        } else {
          this.hasSameEmail = false;

          // Manager already exists => user has to change his email
          const existingManager = await this.loadUserByEmail(this.manager.email!);

          if (existingManager.length > 0) {
            this.hasSameEmail = true;
            return this.$notifyErrorSimplified('TENANT_MANAGE.NOTIFICATIONS.MANAGER_ALREADY_EXISTS');
          }
         // create tenant
          const createdTenant: Tenant = await this.createTenant(this.tenantCopy!);

          // create user
          this.manager.tenantId = createdTenant.id;

          const userRoles = await this.loadUserRoles({tenantId: createdTenant.id});
          this.manager.roleId = userRoles.find((role) => role.tenantAdmin)!.id;
          delete this.manager.role;

          await this.createUserAction(this.manager);

          // set user count to 1 to avoid NaN
          this.storeTenant(createdTenant);

          await this.$router.push({
            name: 'usersOverview',
            params: {
              tenantId: this.$route.params.tenantId,
            },
          });
          this.$notifySuccessSimplified('TENANT_MANAGE.NOTIFICATIONS.TENANT_CREATED');
        }
      } catch (e) {
        if (e.status === 409) {
          this.hasSameName = true;
        } else if (e.status === 406) {
          this.hasSamePrefix = true;
        }
        if (this.$te(`TENANT_MANAGE.NOTIFICATIONS.${e.status}.TITLE`)) {
          this.$notifyErrorSimplified(`TENANT_MANAGE.NOTIFICATIONS.${e.status}`);
        } else {
          this.$notifyErrorSimplified(`GENERAL.NOTIFICATIONS.GENERAL_ERROR`);
        }
      }
    }
  }

  /**
   * Tells the parent to close the modal window
   */
  public cancelButtonClicked() {
    this.$emit('close');
  }

  @Watch('tenant')
  private onTenantChange() {
    this.tenantCopy = Tenant.parseFromObject(this.tenant.parseToObject());
  }
}
